import set = Reflect.set;

const moment = require("moment");
import {bindable, inject} from 'aurelia-framework';
import {DialogService} from "aurelia-dialog";
import {HttpClient} from 'aurelia-http-client'
import {fhirEnums} from "../classes/fhir-enums";
import {UserService} from "../services/UserService";
import {translations} from "../classes/translations";
import {ModalVitalchartAdd} from "./modal-vitalchart-add";
import {LOINC} from "../classes/Codes";
import {PatientItem} from "../classes/Patient/PatientItem";
import {Rest} from "../classes/FhirModules/Rest";
import {DialogMessages} from "../services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {ReportService} from "../services/ReportService";
import {ConfigService} from "../services/ConfigService";

@inject(DialogService, DialogMessages, I18N)
export class PatientCurveVitalchart {
    @bindable patient: PatientItem;
    @bindable dateFrom: Date;
    @bindable dateTo: Date;
    @bindable numAxesChanged: Function;
    dialogService: DialogService;
    shown: boolean = true;
    i18n: I18N;

    indicators = {
        bodyTemperature: [],
        systolicBloodPresure: [],
        diastolicBloodPressure: [],
        heartRate: [],
        oxygenSaturation: [],
        respiratoryRate: []
    };

    chartOptions = {};

    isLoading = false;

    enableAlphatron;
    alphatronUrl;
    alphatronUser;
    alphatronPass;
    dialogMessages: DialogMessages;
    public static AlphaTronVitals;

    _showReportButton : boolean = undefined; // backing field to avoid multiple loading/processing of the config
    get showReportButton() {
        if (typeof this._showReportButton === "undefined") {
            const cfg = ConfigService.GetFormSettings('curve');
            this._showReportButton = !!ReportService.ReportServer
                && typeof cfg !== "undefined"
                && typeof cfg.report !== "undefined"
                && typeof cfg.report.name !== "undefined"
                && cfg.report.name !== "";
        }

        return this._showReportButton;
    }

    constructor(dialogService, dialogMessages, i18n) {
        if (ConfigService.Debug) window["curve"] = this;
        this.dialogService = dialogService;
        this.dialogMessages = dialogMessages;
        this.i18n = i18n;
        this.enableAlphatron = PatientCurveVitalchart.AlphaTronVitals && PatientCurveVitalchart.AlphaTronVitals.enabled;

        if (this.enableAlphatron) {
            this.alphatronUrl = PatientCurveVitalchart.AlphaTronVitals.url;

            // get user:pass from env
            let tmp = atob(PatientCurveVitalchart.AlphaTronVitals.userHash);
            let sa = tmp.split(':');
            this.alphatronUser = sa[0];
            this.alphatronPass = sa[1];
            this.alphatronUrl = PatientCurveVitalchart.AlphaTronVitals.url;
        }
    }

    attached() {
    }

    toggleExpand(node) {
        this.shown = !this.shown;

        if (!this.shown) {
            node.classList.add('collapsed');
        } else {
            node.classList.remove('collapsed');
        }
    }

    add() {
        if (this.isLoading) return false;

        // noinspection JSIgnoredPromiseFromCall
        this.dialogService.open({
            viewModel: ModalVitalchartAdd, model: {patient: this.patient}
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.updateData();
            }
        })

        return false;
    }

    refresh() {
        if (this.isLoading) return false;
        this.updateData();
        return false;
    }

    patientChanged() {
        this.updateData();
    }

    dateFromChanged() {
        this.updateData();
    }

    dateToChanged() {
        this.updateData();
    }

    updateData() {
        if (!this.patient || this.isLoading) return;

        this.isLoading = true;

        // wait so both datefrom and dateto get synced
        setTimeout(() => {
            this.getObservations(this.patient.id).then((observations) => {
                this.filterObservations(observations);

                this.renderChart();
                this.isLoading = false;
            });
        }, 30);
    }

    getObservations(patientId): Promise<any[]> {
        const dateFrom = moment(this.dateFrom).startOf('day').format('YYYY-MM-DDTHH:mm');
        const dateTo = moment(this.dateTo).endOf('day').format('YYYY-MM-DDTHH:mm');

        return Rest.Fetch(`Observation?subject=${patientId}&date=ge${dateFrom}&date=le${dateTo}`)
            .then((result: any[]) => {
                return result.filter((item) => {
                    return item.resourceType === fhirEnums.ResourceType.observation;
                });
            });
    }

    filterObservations(observations: any[]) {
        this.indicators = {
            bodyTemperature: [],
            systolicBloodPresure: [],
            diastolicBloodPressure: [],
            heartRate: [],
            oxygenSaturation: [],
            respiratoryRate: []
        };

        observations.forEach((observation: any) => {
            if (observation.hasOwnProperty('code')) {
                this.parseObservations(observation, observation.code);
            } else if (observation.hasOwnProperty('category')) {
                observation.category.forEach((category: any) => {
                    this.parseObservations(observation, category);
                });
            }
        });
    }

    parseObservations(observation, category) {
        if (category.hasOwnProperty('coding')) {
            category.coding.forEach((coding: any) => {
                if (coding.system === LOINC.SYSTEM) {
                    switch (coding.code) {
                        case LOINC.CODES.BODY_TEMPERATURE.code: {
                            this.indicators.bodyTemperature.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                            break;
                        }
                        case LOINC.CODES.BLOOD_PRESSURE_SYSTOLIC.code: {
                            this.indicators.systolicBloodPresure.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                            break;
                        }
                        case LOINC.CODES.BLOOD_PRESSURE_DIASTOLIC.code: {
                            this.indicators.diastolicBloodPressure.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                            break;
                        }
                        case LOINC.CODES.HEART_RATE.code: {
                            this.indicators.heartRate.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                            break;
                        }
                        case LOINC.CODES.OXYGEN_SATURATION.code: {
                            this.indicators.oxygenSaturation.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                            break;
                        }
                        case LOINC.CODES.RESPIRATORY_RATE.code: {
                            this.indicators.respiratoryRate.push({
                                date: new Date(observation.effectiveDateTime),
                                value: observation.valueQuantity.value
                            });
                        }
                        default: {
                            console.warn('LOINC code not recognized: ' + coding.code);
                        }
                    }
                }
            });
        }
    }

    renderChart() {
        const dateFromMoment = moment(this.dateFrom).startOf('day');
        const dateToMoment = moment(this.dateTo).add(1, 'day').startOf('day');
        const dateFrom = dateFromMoment.format('YYYY-MM-DDTHH:mm');
        const dateTo = dateToMoment.format('YYYY-MM-DDTHH:mm');
        const diff = dateToMoment.diff(dateFromMoment, 'days');
        let scale = diff * 240;

        switch (diff) {
            case 1:
                scale = 240;
                break;
            case 2:
                scale = 360;
                break;
            case 3:
                scale = 480;
                break;
            case 4:
            case 5:
                scale = 720;
                break;
            case 6:
            case 7:
                scale = 1440;
                break;
        }

        const bodyTempData = this.indicators.bodyTemperature.map((observation) => {
            return {
                x: observation.date,
                y: observation.value
            };
        }).sort((a, b) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const bloodPressureData = this.indicators.systolicBloodPresure.map((observation) => {
            const diastolicData = this.indicators.diastolicBloodPressure.find((item) => moment(item.date).valueOf() === moment(observation.date).valueOf());

            return {
                x: observation.date,
                y: [
                    observation.value,
                    typeof diastolicData !== 'undefined' ? diastolicData.value : observation.value
                ]
            };
        }).sort((a, b) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const heartRateData = this.indicators.heartRate.map((observation) => {
            return {
                x: observation.date,
                y: observation.value
            };
        }).sort((a, b) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const oxygenSaturationData = this.indicators.oxygenSaturation.map((observation) => {
            return {
                x: observation.date,
                y: observation.value
            };
        }).sort((a, b) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const respiratoryRateData = this.indicators.respiratoryRate.map((observation) => {
            return {
                x: observation.date,
                y: observation.value
            };
        }).sort((a, b) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const chartOptions = {
            width: "100%",
            height: "290px",
            xAxis: {
                rangeFrom: dateFrom,
                rangeTo: dateTo,
                scale: scale
            },
            yAxis: [{
                name: translations.translate("bodytemp"),
                unit: '°C',
                type: 'line',
                color: '#3333FF',
                scale: 0.5,
                rangeFrom: 36,
                rangeTo: 42,
                rangeStatic: false,
                data: bodyTempData
            }, {
                name: translations.translate("bloodpressure"),
                unit: 'mmHg',
                type: 'candle',
                color: '#FF3333',
                scale: 5,
                rangeFrom: 80,
                rangeTo: 140,
                rangeStatic: false,
                data: bloodPressureData
            },]
        };

        if (heartRateData.length > 0) {
            chartOptions.yAxis.push({
                name: translations.translate("heart_rate"),
                unit: 'bpm',
                type: 'line',
                color: '#339933',
                scale: 5,
                rangeFrom: 50,
                rangeTo: 90,
                rangeStatic: false,
                data: heartRateData
            });
        }

        if (oxygenSaturationData.length > 0) {
            chartOptions.yAxis.push({
                name: translations.translate("oxygen_saturation"),
                unit: '%',
                type: 'line',
                color: '#ff9b00',
                scale: 5,
                rangeFrom: 0,
                rangeTo: 100,
                rangeStatic: true,
                data: oxygenSaturationData
            });
        }

        if (respiratoryRateData.length > 0) {
            chartOptions.yAxis.push({
                name: translations.translate("respiratory_rate"),
                unit: '/min',
                type: 'line',
                color: '#bb00ff',
                scale: 5,
                rangeFrom: 0,
                rangeTo: 100,
                rangeStatic: true,
                data: respiratoryRateData
            });
        }

        if (typeof this.numAxesChanged === "function")
            this.numAxesChanged({val: chartOptions.yAxis.length});

        this.chartOptions = chartOptions;
    }

    printCurve() {
        let cfg = ConfigService.GetFormSettings('curve');
        if (cfg && cfg.report && cfg.report.name) {
            ReportService.Preview(this.patient.latestAssessment.id, cfg.report.name)
        }
    }

    async getAlphatronVitals() {
        if (this.isLoading)
            return;

        /* if (!this.patient.identifier || !this.patient.identifier[0].value || !this.patient.encounter.identifier || !this.patient.encounter.identifier[0].value) {
            console.error('Patient has no identifier or Encounter has no identifier');
            return;
        } */

        this.isLoading = true;

        try {
            let url = PatientCurveVitalchart.AlphaTronVitals.url;
            let encounterId = this.patient.encounter.id;

            if (this.patient.encounter && this.patient.encounter.identifier) {
                let ident: any = this.patient.encounter.identifier.find(o => o.system.endsWith('/visitNumbers'));
                if (!ident) ident = this.patient.encounter.identifier.find(o => o.system.endsWith('/sourceId'));
                if (ident) encounterId = ident.value;
            }

            /*if (this.patient.encounter.identifier && this.patient.encounter.identifier[0] && this.patient.encounter.identifier[0].value) {
                encounterId = this.patient.encounter.identifier[0].value;
            }*/

            let patientId = this.patient.id;
            if (this.patient.identifier) {
                let hosp = this.patient.identifier.find(o => o.system.endsWith("/patientNumbers"));
                if (!hosp) hosp = this.patient.identifier.find(o => o.use === "official");
                if (!hosp) hosp = this.patient.identifier.find(o => o.value);

                if (hosp) patientId = hosp.value;
            }

            url = url.replace("%LOGINNAME%", encodeURIComponent(UserService.UserName));
            url = url.replace("%PATIENTID%", encodeURIComponent(patientId));
            url = url.replace("%ENCOUNTERID%", encodeURIComponent(encounterId));
            url = url.replace("%EMRUSER%", this.alphatronUser);
            url = url.replace("%EMRPASS%", this.alphatronPass);

            if (this.patient && this.patient.name) {
                let officialName = this.patient.name.find(o => o.use === 'official');
                if (!officialName) officialName = this.patient.name[0];
                url = url.replace("%LASTNAME%", officialName.family);

                let givenName = officialName.given?.join(', ');
                url = url.replace("%FIRSTNAME%", givenName);

                url = url.replace("%BORN%", moment(this.patient.birthDate).format("YYYYMMDD"));

                let gndr = 'U';
                if (this.patient.gender) {
                    gndr = String(this.patient.gender)[0].toUpperCase();
                }

                url = url.replace("%GENDER%", gndr);
            }

            const client = new HttpClient();

            client.get(url).then(result => {
                setTimeout(this.updateData.bind(this), 3000);
                // let s = JSON.stringify(result);
                // if (s && s.toUpperCase().indexOf('SUCCESS.') > -1) {
                //     if (ConfigService.Debug) console.debug("Case for Patient " + patientId + " should now open");
                // } else {
                //     let msg = result.response;
                //     if (!msg) msg = JSON.stringify(result);
                //     throw(msg);
                // }
            }).catch(e => {
                this.dialogMessages.prompt("Something went wrong<br />" + (typeof e === "string" ? e : JSON.stringify(e)), this.i18n.tr("error"), true);
                this.isLoading = false;
            });
        } finally {
            this.isLoading = false;
        }
    }
}
